// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-fruehbucher-js": () => import("./../../../src/pages/fruehbucher.js" /* webpackChunkName: "component---src-pages-fruehbucher-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kreuzfahrten-js": () => import("./../../../src/pages/kreuzfahrten.js" /* webpackChunkName: "component---src-pages-kreuzfahrten-js" */),
  "component---src-pages-reiseberichte-js": () => import("./../../../src/pages/reiseberichte.js" /* webpackChunkName: "component---src-pages-reiseberichte-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-blogposts-js": () => import("./../../../src/templates/blogposts.js" /* webpackChunkName: "component---src-templates-blogposts-js" */)
}

